import {Status} from "@/views/commons/search/utils/enums";
import {Errors} from "@/enums/response_errors";

const state = () => ({
    mixinResults: {
      body: null,
      Status,
      Errors,
      status: Status.NONE,
      valid: true,
      valid_status: Status.NONE,
      count_result: null,
      error: null,
      verbose_body: null,
      stored_results: [],
      offset_next_page: null
    },
});

const getters = {
  getMixinResults: (state) => state.mixinResults,
};

const mutations = {
    setResultMixin(state, data) {
      state.mixinResults.body = data.body;
      state.mixinResults.status = data.status;
      state.mixinResults.offset_next_page = data.fetch_result.offset_next_page;
      if (state.mixinResults.stored_results.results && state.mixinResults.stored_results.results.length > 0) {
        state.mixinResults.stored_results.results.push(...data.fetch_result.results);
      } else{
        state.mixinResults.stored_results = data.fetch_result;
      }
    },
    setSpecificKeyResultMixin(state, data){
      state.mixinResults[data.key] = data.value;
    }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};