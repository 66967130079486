/**
 * A common parents for enums.
 */
class TextValueEnum {
    constructor(value, text, component= null) {
        if (!component) {
            component = value;
        }
        this.value = value;
        this.text = text;
        this.component = component;
    }
}

export {
    TextValueEnum,
};