import { CriterionType } from "@/views/commons/search/utils/enums";

export default {
  methods: {
    normalize(question) {
      if (question) {
        if (question.query) {
          question.query.type = CriterionType.GROUP.value;
          question.query.criteria.forEach((c) => {
              if (c.type.value) {
                c.type = c.type.value;
              }
          });
          if (question.sub_questions) {
            question.sub_questions.forEach((sub_q) => {
              this.normalize(sub_q);
            });
          }
        } else if (question.criteria) {
          question.type = question.type.value;
          question.criteria.forEach((c) => {
            if (c.type.value) {
              c.type = c.type.value;
            }
          });
        }
        if (question.autocomplete) {
          if (Array.isArray(question.autocomplete)) {
            question.autocomplete.forEach(obj => {
              if (typeof obj.query.type === 'object') {
                obj.query.type = obj.query.type.value;
              }
            });
          } else {
            if (question.autocomplete.query && typeof question.autocomplete.query.type === 'object') {
              question.autocomplete.query.type = question.autocomplete.query.type.value;
            }
          }
        }
      }
      return question;
    },

    denormalize(question) {
      if (question.query) {
        question.query.type = CriterionType.GROUP;
        question.query.criteria.forEach((c) => {
          c.type = Object.values(CriterionType).find((o) => o.value === c.type);
        });
      }
      return question;
    },

    slugify(input_value) {
      // Transform string to lowercase & replace special characters and spaces with underscores
      // Example: slugify("Do you smoke?") -> "do_you_smoke"
      let str = input_value.toLowerCase().trim().replace(/[^\w\s]|_/g, "_").replace(/\s+/g, "_");
      while (str.endsWith("_")) {
        str = str.slice(0, -1);
      }
      return str;
    }
  },
};
