import {TextValueEnum} from "@/utils/enums";

/**
 * List of errors returns from api
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const Errors = {
   /* Basics errors */
    NO_ACTIVE_ACCOUNT: new TextValueEnum("no_active_account", "Identifiant ou mot de passe invalide"),
    UNAUTHORIZED: new TextValueEnum("unauthorized", "Accès refusé: problème d'identification"),
    FORBIDDEN: new TextValueEnum("forbidden", "Accès refusé: vous n'avez pas les droits"),
    NOT_FOUND: new TextValueEnum("not_found", "La ressource demandée n'a pas été trouvée"),
    TOO_MANY_REQUESTS: new TextValueEnum("too_many_requests", "Vous avez envoyé trop de requêtes, veuillez patienter"),

    /* Opensearch-related errors */
    OPENSEARCH_UNKNOWN_ERROR: new TextValueEnum("opensearch_unknown_error", "Une erreur s'est produite lors de la communication avec OpenSearch."),
    OPENSEARCH_AUTHENTICATION_FAILED: new TextValueEnum("opensearch_authentication_failed", "Échec de l'authentification sur OpenSearch."),
    OPENSEARCH_PARENTHESIS_ERROR: new TextValueEnum("opensearch_parenthesis_error",  "Une parenthèse ouvrante ou fermante est manquante."),
    OPENSEARCH_EXCEEDS_MAX_CLAUSE_COUNT: new TextValueEnum("opensearch_exceeds_max_clause_count",  "Une recherche par préfixe (ex: \"bi*\") correspond à trop de termes potentiels et doit être affinée (ex: \"bidi*\")."),
    OPENSEARCH_ILLEGAL_CHARACTER_ERROR: new TextValueEnum("opensearch_illegal_character_error", "Un caractère illégal (comme ' ou \") est utilisé sans contexte, veuillez vérifier la syntaxe."),
    OPENSEARCH_OR_AND_SAME_LEVEL_ERROR: new TextValueEnum("opensearch_or_and_same_level_error", "Les conjonctions 'OR' et 'AND' ne peuvent pas être au même niveau, veuillez utiliser des parenthèses."),
    OPENSEARCH_TOO_MANY_CONCEPTS_SELECTED: new TextValueEnum("opensearch_too_many_concepts_selected", "Trop de concepts ont été sélectionnés (>1024), veuillez affiner votre recherche et la relancer."),

    /* Workspace-related errors */
    WKS_IMPORT_LIMIT_EXCEEDED : new TextValueEnum("wks_import_limit_exceeded", "Trop de patients ont été ajoutés à l’espace de travail. La limite est fixée à 3000 patients."),
    WKS_USER_NOT_ALLOWED : new TextValueEnum("wks_user_not_allowed", "Vous n’avez pas les droits pour accéder à ce projet. Pour plus d’information, contactez le responsable du projet."),
};
