<template>
  <div
    v-if="showBreadcrumb || showTabs"
    class="all-breadcrumb d-flex w-100 bg-basics-10 overflow-hidden text-truncate px-2"
    :class="{
      'fix-breadcrumbs':
        $router.currentRoute.name === 'SdeDetail' ||
        $router.currentRoute.name === 'Cohort instance' ||
        $router.currentRoute.name === 'CohortPatientRecord'
    }"
  >
    <v-breadcrumbs v-if="showBreadcrumb" :items="breadCrumbs">
      <template #divider>
        <icon id="right-arrow-cohort" name="caret-right" outline color="basics-100" />
      </template>
      <template #item="{ item }">
        <div class="d-flex">
          <v-breadcrumbs-item
            :class="{
              'last-item': index(item.text) === breadCrumbs.length - 1
            }"
            class="clickable"
            @click="goTo(item)"
          >
            <span class="max-w-17-vw text-truncate" :class="{'unclickable': !item.subtitle}">
              {{ item.text }}
            </span>

            <span
              v-if="item.subtitle"
              class="max-w-70-vw ml-2 basics-70 text-truncate"
            >
              {{ item.subtitle }}
            </span>
          </v-breadcrumbs-item>
        </div>
      </template>
    </v-breadcrumbs>

    <v-divider v-if="showTabs" vertical class="divider-breadcrumbs ml-3" />
    <tab-list
      v-if="showTabs"
      :default-tab="tab"
      :items="tabs"
      :icon-size="20"
      @update-tab="onTabChange"
    />

    <!-- Dialog to display title and description -->
    <dialog-box :dialog="displayDetails" icon="book-closed" width="700px" is-form-valid type="info"
                @close="displayDetails = false; item = {}"
                @submit="displayDetails = false; item = {}"
    >
      <template #default>
        <breadcrumbs-details-dialog :details="item" />
      </template>
    </dialog-box>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import { mapGetters } from "vuex";
import BreadcrumbsDetailsDialog from "@/components/commons/dialog/BreadCrumbsDetailsDialog.vue";

export default {
  name: "BreadCrumbs",
  components: { BreadcrumbsDetailsDialog },
  mixins: [i18n],

  data() {
    return {
      displayDetails: false,
      item: {},
      nextTo: null,
    };
  },

  computed: {
    ...mapGetters({
      breadCrumbs: "breadcrumbs/getBreadCrumbs",
      tabs: "breadcrumbs/getTabs",
    }),

    showBreadcrumb() {
      return this.breadCrumbs && this.breadCrumbs.length > 0;
    },

    showTabs() {
      return this.tabs && this.tabs.length > 0;
    },

    tab: {
      get() {
        return this.$store.getters["breadcrumbs/getSelectedTab"];
      },

      set(index) {
        return this.$store.dispatch("breadcrumbs/setTabIndex", index);
      },
    },
  },

  watch: {
    $route() {
      if (!this.nextTo) {
        this.$store.commit("breadcrumbs/setBreadCrumbs", []);
        this.$store.commit("breadcrumbs/setTabs", []);
      } else {
        this.$store.commit("breadcrumbs/setBreadCrumbs",[
            { text: "Cohortes", to: { name: "Cohorts" }, exact: true },
            { text: this.nextTo.text, to: {
                name: this.nextTo.to.name,
                params: { cohortId: this.nextTo.to.params.cohortId.toString() },
              },
            },]);
        this.$store.commit("breadcrumbs/setTabs", []);
      }
    },
  },

  methods: {
    index(text) {
      return this.breadCrumbs.map((i) => i.text).indexOf(text);
    },

    goTo(item) {
      if (item.to && !item.subtitle) {
        this.$router.push(item.to);
      }
      if (item.subtitle) {
        this.displayDetails = true;
        this.item = item;
      }
      this.nextTo = item.to?.name === 'Cohort instance' ? item : null;
    },

    onTabChange(index) {
      this.$store.dispatch("breadcrumbs/setTabIndex", index);
      this.tab = index;
    },
  },
};
</script>

<style>
.v-breadcrumbs {
  padding: 12px 0 12px 16px !important;
  min-width: max-content !important;
}

.v-breadcrumbs__item {
  color: var(--basics-80) !important;
  font-size: var(--text-body-2-font-size) !important;
  font-weight: var(--font-demi-bold) !important;
  min-width: max-content !important;
}

.v-breadcrumbs__divider {
  padding: 0 4px !important;
}

.v-breadcrumbs__divider > svg {
  width: 16px !important;
  height: 17px !important;
}

.last-item > .v-breadcrumbs__item {
  color: var(--basics-100) !important;
}

.v-breadcrumbs__item:hover:not(.last-item > .v-breadcrumbs__item) {
  color: var(--primary-main) !important;
}

.v-breadcrumbs__item:active:not(.last-item > .v-breadcrumbs__item) {
  color: var(--primary-pressed) !important;
}

.divider-breadcrumbs {
  color: var(--basics-50) !important;
  width: 2px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.all-breadcrumb {
  border-bottom: inset 2px var(--basics-50) !important;
}

.all-breadcrumb .v-tab--active:not(.navbar) {
  box-shadow: none !important;
}

.fix-breadcrumbs {
  position: fixed !important;
  z-index: 100 !important;
}

.sde-banner-alert {
  margin-top: 45px;
  padding-bottom: unset !important;
}

.max-w-17-vw {
  max-width: 17vw !important;
}

.max-w-70-vw {
  max-width: 70vw !important;
}
</style>