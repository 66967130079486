<template>
  <div class="text-body-2">
    <div class="border-bottom bg-basics-10">
      <v-container class="py-6">
        <v-row class="justify-between">
          <v-col>
            <span class="basics-80">{{ t("Bienvenue,") }}</span>
            <h4 class="text-h4 font-demi-bold mt-1">
              {{ user.first_name }}
            </h4>
          </v-col>
          <v-col v-if="!transparencyStandalone()" cols="auto" class="my-auto">
            <div class="d-flex align-center mb-2">
              <icon name="search" outline color="basics-80" />
              <span class="ml-2 mr-1 font-demi-bold">{{
                user.query_count
              }}</span>
              <span class="basics-80">{{ t("Recherches effectuées") }}</span>
            </div>
            <div class="d-flex align-center">
              <icon name="users" color="basics-80" />
              <span class="ml-2 mr-1 font-demi-bold">{{
                user.cohort_count
              }}</span>
              <span class="basics-80">{{ t("Cohortes créées") }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container class="mt-16">
      <div v-if="!transparencyStandalone() && can(Perm.AppSearch.value)">
        <v-row class="mt-4 mb-16 flex-column align-center">
          <v-row>
            <span class="text-h5 font-demi-bold">{{
              t("Explorer les données de l'entrepôt")
            }}</span>
          </v-row>
          <v-row class="w-100 justify-center">
            <v-col cols="8" class="pt-4">
              <text-input
                v-model="inputValue"
                :error-messages="errorMessages"
                :placeholder="t('Rechercher un mot-clé')"
                search-input
                hide-details="auto"
                @keypress.enter="startSearch()"
              />
            </v-col>
          </v-row>
        </v-row>
      </div>

      <v-divider v-if="!transparencyStandalone() && can(Perm.AppSearch.value)" class="mb-8" />

      <v-row>
        <v-col>
          <span class="text-subtitle-1 font-demi-bold">{{
            t("Comment ça fonctionne ?")
          }}</span>
          <span class="basics-80 my-4 d-block">{{
            t(
              "Pour mieux prendre en main notre outil, voici quelques ressources qui peuvent vous être utiles pour commencer"
            )
          }}</span>

          <v-card class="homepage-card-blocks br-8">
            <a
              href="https://documentation.codoc.co/comment-crer-un-projet-de-recherche-avec-la-suite-v2-cnil"
              target="_blank"
              class="homepage-link-hover"
            >
              <div class="title-padding">
                <icon name="book-open" class="mr-3" />
                <span class="font-demi-bold basics-100">{{
                  t("Comment commencer un projet de recherche ?")
                }}</span>
              </div>
            </a>
          </v-card>
          <div class="py-2 px-5 pt-3 border b-basics-50 br-8 w-100 mdp">
            <span class="text-body-2 basics-90">{{
              t("Mot de passe: codoc")
            }}</span>
          </div>

          <v-card class="homepage-card-blocks br-8 mt-6">
            <a
              href="https://documentation.codoc.co/comment-identifier-des-patients-avec-codoc-care-anciennement-dr-warehouse"
              target="_blank"
              class="homepage-link-hover"
            >
              <div class="title-padding">
                <icon name="book-open" class="mr-3" />
                <span class="font-demi-bold basics-100">{{
                  t("Comment réaliser un projet de recherche dans l'EDS ?")
                }}</span>
              </div>
            </a>
          </v-card>
          <div class="py-2 px-5 pt-3 border b-basics-50 br-8 w-100 mdp">
            <span class="text-body-2 basics-90">{{
              t("Mot de passe: codoc")
            }}</span>
          </div>

          <v-card class="homepage-card-blocks br-8 mt-6">
            <a
              href="https://documentation.codoc.co/"
              target="_blank"
              class="homepage-link-hover"
            >
              <div class="title-padding">
                <icon name="book-open" class="mr-3" />
                <span class="font-demi-bold basics-100">{{
                  t("Notre documentation complète")
                }}</span>
              </div>
            </a>
          </v-card>
          <div class="py-2 px-5 pt-3 border b-basics-50 br-8 w-100 mdp">
            <span class="text-body-2 basics-90">{{
              t("Mot de passe: codoc")
            }}</span>
          </div>
        </v-col>
        <v-col>
          <div v-if="!currentNPS" class="homepage-blocks p-4 flex-column">
            <span class="font-demi-bold">{{
              t("Recommanderiez-vous cette application à vos collègues ?")
            }}</span>
            <div class="mt-4 mb-1 w-100 d-flex justify-content-between">
              <v-btn
                v-for="index in 11"
                :key="index"
                class="rate-button"
                small
                :class="
                  index - 1 === currentNPS ? 'primary pointer-none' : 'none'
                "
                :disabled="currentNPS && index - 1 !== currentNPS"
                @click="sendNPS(index - 1)"
              >
                {{ index - 1 }}
              </v-btn>
            </div>
            <div
              class="d-flex justify-content-between basics-70 text-body-2 w-100"
            >
              <span>{{ t("Je ne recommande pas") }}</span>
              <span>{{ t("Je recommande fortement") }}</span>
            </div>
          </div>
          <div v-else class="homepage-blocks p-4 flex-column">
            <span class="font-demi-bold mb-2">{{
              t("Recommanderiez-vous cette application à vos collègues ?")
            }}</span>
            <span v-if="currentNPS > 4" class="ml-1">{{
              t("Votre avis a bien été pris en compte, merci !")
            }}</span>
            <span v-else class="text-center">{{
              t(
                "Votre avis a été enregistré, n’hésitez pas à contacter support@codoc.co pour toute demande d’aide ou remarques"
              )
            }}</span>
          </div>
          <div class="homepage-blocks p-3 mt-6">
            <v-row>
              <v-col cols="2">
                <icon
                  name="mail-open"
                  width="60"
                  height="60"
                  class="padding-custom-sm padding-custom-md"
                />
              </v-col>
              <v-col cols="10" class="align-self-center px-4">
                <span>
                  {{
                    t(
                      "Vous souhaitez être tenu au courant des nouveautés codoc ? Inscrivez vous à notre newsletter"
                    )
                  }}
                  <a href="https://lnkd.in/eFcFpNUx" target="_blank">{{
                    t("ici")
                  }}</a>.
                </span>
              </v-col>
            </v-row>
          </div>
          <div class="homepage-blocks p-3 mt-6">
            <v-row>
              <v-col cols="2">
                <icon
                  name="speech-bubbles"
                  width="60"
                  height="60"
                  class="padding-custom-sm padding-custom-md"
                />
              </v-col>
              <v-col cols="10" class="align-self-center px-4">
                <span>
                  {{
                    t(
                      `Vous souhaitez contribuer au futur de codoc Research Hub | Care ?`
                    )
                  }}
                  <br>
                  {{ t("Réservez un rendez-vous avec codoc ") }}
                  <a
                    href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2c9Ga7V0MVQQ9c4YdiND-ChD2EGYiiHizPMVF55seaeK9D2XBMOCM6EaAsu-7QJ28bc6G8aa8o"
                    target="_blank"
                  >{{ t("ici") }}</a>.
                </span>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-divider v-if="!transparencyStandalone()" class="my-8" />

      <div v-if="!transparencyStandalone()">
        <v-row>
          <v-col>
            <div class="text-subtitle-1 font-demi-bold">
              {{ t("Profondeur de la donnée et exhaustivité") }}
            </div>
            <div class="mt-4 mb-6 basics-80">
              {{
                t(
                  "Votre entrepôt de données de santé (EDS) grandit avec le temps. Ce diagramme vous permet de visualiser le chargement de votre EDS et la profondeur de données correspondantes."
                )
              }}
            </div>
            <div v-if="!chartLoading">
              <div
                v-if="chartData && chartData.length > 0"
                class="border b-basics-50 br-8 p-4 bg-basics-10"
              >
                <plotly
                  :data="chartData"
                  :layout="chartLayout"
                  :display-mode-bar="false"
                />
              </div>
              <div
                v-else
                class="rounded-shadow-container bg-basics-10 br-8 text-center py-4 font-demi-bold text-subtitle-1"
              >
                {{
                  t("Les données pour générer ce tableau sont indisponibles")
                }}
              </div>
            </div>
            <v-skeleton-loader v-else type="table-thead, table-tbody" />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Plotly } from "vue-plotly";
import { FeedbackNPS } from "@/services/api";
import { Perm } from "@/enums/permissions";
import Charts from "@/mixins/chart";
import cloneDeep from "lodash/cloneDeep";
import {SUITE} from "@/config";

export default {
  name: "Home",
  components: { Plotly },
  mixins: [Charts],

  data() {
    return {
      Perm,
      chartLoading: false,
      chartData: [],
      chartLayout: {
        margin: { t: 0, b: 40, r: 30, l: 100 },
        xaxis: {
          showgrid: false,
          tickmode: "linear",
          dtick: "4",
          tick0: null,
        },

        yaxis: {
          showgrid: false,
          automargin: true,
          tickmode: "linear",
        },
      },

      inputValue: null,
      errorMessages: null,
      currentNPS: null
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.me,
    }),

    ...mapGetters({
      versionAPI: "settings/versionAPI",
      getChartByName: "charts/getChartByName",
      getNps: "nps/getNps",
      customSettings: 'customApp/getCustomSettings'
    }),

    version() {
      return process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : "dev";
    },

    exhaustiveBar() {
      return process.env.VUE_APP_EXHAUSTIVE_HEATMAP;
    }
  },

  created() {
    this.$store.dispatch("settings/getSettings");
    this.$store.dispatch("featureFlag/getFeatureFlagsList");
    if (!this.getChartByName("document-nbByOriginByYear")) {
      this.chartLoading = true;
      this.$store.dispatch("charts/setOriginByYearData").finally(() => {
        this.chartLoading = false;
        this.initializeHeatmap();
      });
    } else {
      this.initializeHeatmap();
    }
    if (!this.getNps) {
      this.$store.dispatch("nps/setPastNps", this.user.id).finally(() => {
        this.currentNPS = this.getNps;
      });
    } else {
      this.currentNPS = this.getNps;
    }
  },

  methods: {
    initializeHeatmap() {
      if (this.getChartByName("document-nbByOriginByYear").data) {
        const data = cloneDeep(
          this.getChartByName("document-nbByOriginByYear")
        );
        data.data = data.data.slice(0, 30);
        let heatmap_x = data.data[0].periods;
        let heatmap_y = [];
        let heatmap_z = [];
        this.chartLayout.xaxis.tick0 = data.data[0].periods[0];
        data.data.forEach((obj) => {
          heatmap_y.push(obj.type);
          heatmap_z.push(
            Array.from(obj.counts, (item) => (item === 0 ? null : item))
          );
        });
        this.chartData = [
          {
            x: heatmap_x,
            y: heatmap_y,
            z: heatmap_z,
            type: "heatmap",
            hoverongaps: false,
            hovertemplate: "%{z} documents %{y} en %{x}<extra></extra>",
            colorbar: {
              title: {
                text: this.t("Nombre de <br>documents"),
              },
            },

            colorscale: [
              [0, "#D5D7DE"],
              [1, "#233C73"],
            ],
          },
        ];
        if (this.exhaustiveBar) {
          let exhaustiveBarData = {
            x: [],
            y: null,
            z: [],
          };
          exhaustiveBarData.y = heatmap_y;
          exhaustiveBarData.x = heatmap_x;
          let tempArray = [];
          heatmap_x.forEach((value) => {
            value === this.exhaustiveBar
              ? tempArray.push(1)
              : tempArray.push(null);
          });
          heatmap_z.forEach(() => {
            exhaustiveBarData.z.push(tempArray);
          });
          this.chartData.push({
            ...exhaustiveBarData,
            type: "heatmap",
            colorscale: [
              [0, "#38601B"],
              [1, "#38601B"],
            ],
            hoverinfo: "none",
            showlegend: false,
            showscale: false,
          });
          this.chartLoading = false;
        }
      } else {
        this.chartData = null;
      }
    },

    sendNPS(nps) {
      const sendNPS = {
        id: this.user.id,
        api_version: this.versionAPI,
        app_version: this.version,
        nps_value: nps,
      };
      FeedbackNPS.create(sendNPS)
        .then(() => {
          this.$store.commit("snackbar/successSnackbar", {
            message: this.t("Note envoyée avec succès."),
          });
          this.currentNPS = nps;
        })
        .catch((error) => {
          this.$store.commit("snackbar/apiErrorSnackbar", error);
        });
    },

    startSearch() {
      if (this.inputValue) {
        this.$store.commit("search/setSearchQuery", this.inputValue);
        this.$router.push({ name: "Search" });
      } else {
        this.errorMessages = this.t("Requiert au moins 1 caractère");
      }
    },

    transparencyStandalone() {
      return this.customSettings.login_page === SUITE.TRANSPARENCY_STANDALONE.name;
    }
  }

};
</script>

<style scoped>
.homepage-blocks {
  border-radius: var(--br-8);
  background-color: var(--basics-10);
  box-shadow: none !important;
  border: 1px solid var(--basics-50);
  align-items: center;
}

.rate-button {
  border-radius: 4px;
  background-color: var(--basics-50);
  height: 30px;
  width: 30px;
}

.padding-custom-sm {
  padding-right: 16px;
}

@media (min-width: 455px) {
  .padding-custom-md {
    padding-right: 0 !important;
  }
}
</style>
