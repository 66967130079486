const DWH_WS_URL = process.env.VUE_APP_DWH_WS_URL;

export default {
  data() {
    return {
      webSockets: {},
    };
  },

  methods: {
    initializeWebSocket(token, key, value, onmessage) {
      this.webSockets[key] = new WebSocket(
        DWH_WS_URL + "/ws/notifications/?access_token=" + token
      );

      this.webSockets[key].onopen = () => {
        this.webSockets[key].send(JSON.stringify(value));
      };
      this.webSockets[key].onmessage = onmessage;
    },

    markAllAsRead(key) {
      this.webSockets[key].send(
        JSON.stringify({
          action: "mark_all_as_read",
          request_id: new Date().getTime(),
        })
      );
    },

    dismiss(key, id) {
      this.webSockets[key].send(
        JSON.stringify({
          action: "mark_as_dismissed",
          request_id: new Date().getTime(),
          pk: id,
        })
      );
    },

    closeSession(key) {
      this.webSockets[key].close();
    },
  },
};
